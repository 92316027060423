<template>
  <div class="contact page-component">
    <div class="container">
      <div class="contact__card">
        <div class="contact__card-content">
          <h3>I look forward to hearing from you!</h3>
          <div class="js-nav-indicator" v-view="(e) => updateNavIndex(e, pageIndex)" :data-index="pageIndex"></div>
          <p>Please feel free to contact me!</p>
          <div class="contact__contact-data">
            <p>Alexander Ehrenhöfer</p>
            <p>
              <simple-svg :src="`/svg/mail.svg`" />
              <a href="mailto:ehren.alex@web.de">ehren.alex(at)web.de</a>
            </p>
            <p>
              <simple-svg :src="`/svg/github.svg`" />
              <a href="https://github.com/aalolexx" target="_blank">github.com/aalolexx</a>
            </p>
          </div>
        </div>
        <!--<img class="contact__card-image" src="/images/alex-transparent.png" />-->
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/js/EventBus.js'

export default {
  name: 'Contact',
  props: ['pageIndex'],

  methods: {
    updateNavIndex (e, index) {
      EventBus.$emit('update-nav-index', index)
    }
  }
}
</script>
